<template>
	<div class="teamDetail">
		<div class="figure_main">
			<div class="icon" @click="active"></div>
			<div class="figure_text">
				<div class="figure_left">
					<h2>{{teamMsg.title}}</h2>
					<p v-for="(item,index) in teamMsg.text" :key="index">{{item}}</p>
					<div class="left_text" v-for="(item,index) in teamMsg.awards" :key="'info-'+index">
						{{item}}
					</div>
				</div>
				<div class="figure_right">
					<img src="../assets/images/team/liyongji.png" />
				</div>
			</div>
			<div class="works">
				<p>代表作品主要有</p>
				<p v-for="(item,index) in teamMsg.works" :key="index">{{item}}</p>
			</div>
		</div>
		<div class="worksImg">
			<h2>代表作品</h2>
			<HlySwipe :list="list" />
		</div>
		<ul class="bottom_list">
			<!-- <li>Howone Cha</li> -->
		</ul>
	</div>
</template>
<script>
import HlySwipe from "@/components/HlySwipe.vue";
export default {
  name: "TeamDetail",
  data() {
    return {
      teamMsg: {
        title: "Yong Gi-Lee",
        text: ["Artistic Director", "Post Production Supervisor"],
        awards: [
          "2006年 获得韩国电影⼈Director’s Cut 最佳调光调⾊奖",
          "2010年 凭借 《狄仁杰之通天帝国》获得第47届台湾电影⾦⻢奖影展最佳视觉效果奖",
          "2011年 凭借 《狄仁杰之通天帝国》获得第30届香港电影⾦像奖最佳视觉效果奖"
        ],
        works: [
          "杀⼈的回忆, 老男孩, 我要复仇,龙⻔⻜甲, 汉江怪物",
          "甜蜜的⼈⽣, 亲切的⾦⼦, 欢迎来到东莫村，形事，汉江怪物，龙的战争，密阳，华丽的休假，好家伙",
          "坏家伙，怪家伙，机器⼈之恋， 观⾳⼭， 转⼭，⼀个⼈的⽪影戏，昆曲红楼梦, 龙⻔⻜甲, ⻜越老⼈院⻩⾦⼤劫案，画⽪2，危险的关系，狄仁杰之神都龙王，圣诞玫瑰，⼀夜惊喜，因为⾕桂花，张丽莉老师的故事 ，中国制造 (中国) 等约1000多部影⽚"
        ]
      },
      list: [
        { img: require("../assets/images/team/1.jpg") },
        { img: require("../assets/images/team/2.jpg") },
        { img: require("../assets/images/team/3.jpg") },
        { img: require("../assets/images/team/4.jpg") },
        { img: require("../assets/images/team/5.jpg") },
        { img: require("../assets/images/team/6.jpg") },
        { img: require("../assets/images/team/7.png") }
      ]
    };
  },
  components: {
    HlySwipe
  },
  methods: {
    active() {
      this.$router.push({ path: "/team" });
    }
  }
};
</script>
<style lang="scss" scoped>
.figure_main {
  padding: 213px 20.4vw 0;
  .icon {
    width: 166px;
    height: 44px;
    margin: 0 auto 50px;
    background: url(../assets/images/team/btn.png) no-repeat;
    background-size: 100%;
    cursor: pointer;
  }
  .figure_text {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid rgba(114, 114, 114, 0.3);
    .figure_left {
      text-align: left;
      > h2 {
        font-size: 32px;
        font-family: "OpenSansRegular";
        margin-bottom: 15px;
      }
      > p {
        font-size: 22px;
        font-family: "OpenSansLight";
      }
      .left_text {
        font-size: 18px;
        font-family: "OpenSansLight";
        padding-right: 160px;
      }
      .left_text:first-of-type {
        margin-top: 20px;
      }
    }
    .figure_right {
      height: 413px;
      margin-bottom: 33px;
      > img {
        height: 413px;
      }
    }
  }
  .works {
    padding: 34px 0 0;
    > p {
      font-size: 18px;
      font-family: "OpenSansLight";
      line-height: 28px;
      text-align: left;
    }
  }
}
.worksImg {
  padding: 100px 0px 0 0;
  /deep/.my-swipe {
    margin: 29px auto 0px;
  }
  > h2 {
    padding-left: 20.4vw;
    font-size: 22px;
    font-family: "OpenSansLight";
    text-align: left;
  }
  .figure_right {
    height: 413px;
    margin-bottom: 33px;
    > img {
      height: 413px;
    }
  }
}
.works {
  padding: 34px 0 0;
  > p {
    font-size: 18px;
    font-family: "OpenSansLight";
    line-height: 28px;
    text-align: left;
  }
}
.bottom_list {
  padding: 82px 21vw 200px;
  > li {
    font-size: 22px;
    font-family: "OpenSansLight";
    line-height: 30px;
    text-align: left;
    list-style: disc;
  }
}
</style>