<template>
	<div class="my-swipe">
		<swiper ref="mySwiper" :options="swiperOption" @slideChangeTransitionStart="slideChangeTransitionEnd">
			<swiper-slide v-for="(item,index) of list" :key="index">
				<img :src="item.img" alt="">
			</swiper-slide>
		</swiper>
		<div class="swiper-scrollbar">
			<div class="bar" :style="{width:barWidht,left:barLeft}"></div>
		</div>
	</div>
</template>

<script>
export default {
  props: ["list"],
  data() {
    return {
      swiperOption: {
        centeredSlidesBounds: true,
        slidesPerView: "auto",
        spaceBetween: 20,
        loop: true
      },
      activeIndex: 0,
      timer: null
    };
  },
  methods: {
    slideChangeTransitionEnd(e) {
      this.activeIndex = e.activeIndex - this.list.length;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.mySwiper.slideNext();
    }, 3000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  computed: {
    barWidht() {
      if (this.list) {
        if (this.list.length) {
          return (1 / this.list.length) * 100 + "%";
        }
      }
      return 0;
    },
    barLeft() {
      if (this.list) {
        if (this.activeIndex >= this.list.length) {
          return "0%";
        }
        if (this.activeIndex <= -1) {
          return (1 / this.list.length) * 100 * (this.list.length - 1) + "%";
        }
        return (1 / this.list.length) * 100 * this.activeIndex + "%";
      }
      return "0%";
    },
    mySwiper() {
      return this.$refs.mySwiper.$swiper;
    }
  }
};
</script>

<style lang="scss">
.my-swipe {
  width: 100%;
  height: 500px;
  color: #fff;
  font-size: 30px;
  margin: 90px auto 200px;
  text-align: center;
  position: relative;
}
.swiper-container {
  width: 100%;
  height: 100%;
  .swiper-slide {
    width: auto;
    height: 470px;
    img {
      width: auto;
      height: 100%;
    }
  }
}
.swiper-scrollbar {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  background: rgba($color: #fff, $alpha: 0.3);
  transform: translateX(-50%);
  width: 64.6vw;
  position: relative;
  .bar {
    height: 2px;
    background: #fff;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s;
  }
}
</style>
